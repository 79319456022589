





































































import Vue from "vue";
import { mixin as clickaway } from "vue-clickaway";
import Account from "@/components/Svg/Account.vue";
import Configure from "@/components/Svg/Configure.vue";
import Logout from "@/components/Svg/Logout.vue";
import Organization from "@/components/Svg/Organization.vue";
import Status from "@/components/Account/Status.vue";

export default Vue.extend({
  name: "Dropdown",
  components: {
    Account,
    Configure,
    Logout,
    Organization,
    Status,
  },
  mixins: [clickaway],
  data() {
    return {
      isVisible: false,
      focusedIndex: 0,
      name: "",
    };
  },
  mounted() {
    if (this.$store.getters.getUser) {
      this.name = this.$store.getters.getUser.name;
    }
    return "";
  },
  methods: {
    toggleVisibility() {
      this.isVisible = !this.isVisible;
    },
    hideDropdown() {
      this.isVisible = false;
      this.focusedIndex = 0;
    },
    async logout() {
      await this.$store.dispatch("LOGOUT");
      this.$router.push("/login");
    },
    getInitials: (name: string) => {
      if (name !== "") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore */
        return name.match(/\b(\w)/g).join("");
      }
      return "";
    },
  },
});
