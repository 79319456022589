












import Vue from "vue";
import jsonAPI from "@/api";
import MailError from "@/components/Svg/MailError.vue";
import Checkmark from "@/components/Svg/Checkmark.vue";

export default Vue.extend({
  name: "Status",
  components: {
    MailError,
    Checkmark,
  },
  computed: {
    activeClass() {
      return !this.$store.getters.isActive ? "inactive" : "";
    },
    isActive() {
      return this.$store.getters.isActive;
    },
  },
  async mounted() {
    try {
      const response = await jsonAPI.get("organization/current");
      this.$store.dispatch("SET_ORGANIZATION", response.data);
    } catch (error) {
      this.$router.push({ name: "organization.create" });
    }
  },
});
