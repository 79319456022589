













export default {
  name: "LimitNotice",
};
