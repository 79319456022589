



























import Vue from "vue";

export default Vue.extend({
  name: "Breadcrumb",
  data() {
    return {
      breadcrumbList: [],
    };
  },
  watch: {
    $route() {
      this.updateList();
    },
  },
  mounted() {
    this.updateList();
  },
  methods: {
    routeTo(pRouteTo: string) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (this.breadcrumbList[pRouteTo].link) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.$router.push({ name: this.breadcrumbList[pRouteTo].link });
      }
    },
    updateList() {
      if (this.$route.meta) {
        this.breadcrumbList = this.$route.meta.breadcrumb;
      }
    },
  },
});
