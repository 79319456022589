<template>
  <svg height="20" width="20" viewBox="0 0 512 469" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M490.334 85.668H90.526l-5.943-66.207C83.611 8.634 74.537.338 63.667.338H21c-11.598 0-21 9.402-21 21s9.402 21 21 21h23.468C57.293 185.22 24.147-184.077 68.621 311.427c1.714 19.394 12.193 40.439 30.245 54.739C66.319 407.73 96.057 469.005 149 469.005c43.942 0 74.935-43.826 59.866-85.334h114.936c-15.05 41.455 15.876 85.334 59.866 85.334 35.106 0 63.667-28.561 63.667-63.667s-28.561-63.667-63.667-63.667H149.142c-15.952 0-29.853-9.624-35.853-23.646l335.608-19.724a21.002 21.002 0 0019.141-15.87l42.67-170.67c3.308-13.234-6.71-26.093-20.374-26.093zM149 427.005c-11.946 0-21.666-9.72-21.666-21.667 0-11.947 9.72-21.667 21.666-21.667 11.947 0 21.667 9.72 21.667 21.667 0 11.947-9.72 21.667-21.667 21.667zm234.667 0c-11.947 0-21.667-9.72-21.667-21.667 0-11.947 9.72-21.667 21.667-21.667 11.947 0 21.667 9.72 21.667 21.667 0 11.947-9.72 21.667-21.667 21.667zm47.366-169.726l-323.397 19.005-13.34-148.617h369.142l-32.405 129.612z"
      fill="#8497B0"
      fill-rule="nonzero"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgList'
};
</script>

<style lang="scss" scoped></style>
