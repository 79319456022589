














































import Vue from "vue";
import { mapGetters } from "vuex";
import Activities from "@/components/Svg/Activities.vue";
import Breadcrumb from "@/components/Nav/Breadcrumb.vue";
import Customers from "@/components/Svg/Customers.vue";
import Dropdown from "@/components/Account/Dropdown.vue";
import LimitNotice from "@/components/Account/LimitNotice.vue";
import List from "@/components/Svg/List.vue";
import Logo from "@/components/Svg/Logo.vue";
import ThunderMove from "@/components/Svg/ThunderMove.vue";
import Permission from "@/utils/permission";
import Component from "vue-class-component";
import Status from "@/components/Account/Status.vue";

@Component({
  name: "DefaultLayout",
  extends: Permission,
  components: {
    Activities,
    Breadcrumb,
    Customers,
    Dropdown,
    LimitNotice,
    List,
    Logo,
    Status,
    ThunderMove,
  },
  computed: {
    ...mapGetters({ limitReacted: "limitReacted" }),
    showNav(): boolean {
      if (this.$route.meta) {
        return this.$route.meta.auth;
      }
      return false;
    },
  },
  methods: {
    style() {
      return {
        // On smaller devices the page needs padding bottom
        // equal to the nav height
        paddingBottom: `${this.$data.navHeight}px`,
        paddingTop: `${this.$data.paddingTop}`,
      };
    },
  },
})
export default class Default extends Vue {
  data() {
    return {
      navHeight: "",
      paddingTop: "",
    };
  }
}
