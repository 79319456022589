export default {
  methods: {
    hasPermission(permissionSlug) {
      const permissionsList = this.$store.getters.getPermissions;
      if (permissionsList.some((e) => e.slug === permissionSlug)) {
        return true;
      }
      return false;
    },
  },
};
